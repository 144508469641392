import React, { useEffect, useState } from 'react';
import FadeInSection from './FadeInSection';
import { apiService } from '../utils/ApiService';

interface CompanyInfo {
  id: number;
  name: string;
  postcode: string;
  address: string;
  map_url: string;
  establishment_date: string;
  business: string;
  googlemap_iframe: string
}

const CompanyInfoSection: React.FC = () => {
  const [company, setCompanyInfo] = useState<CompanyInfo>(); // JSONデータを格納する状態


  useEffect(() => {
    apiService.get<CompanyInfo>('/company-info')
      .then((data: CompanyInfo) => setCompanyInfo(data))
      .catch((error: any) => console.error('Error fetching news articles:', error));
  }, []);

  return (
    <section className="py-8 md:py-12 md:bg-gray-50">
      
      <h2 className="text-xl md:text-3xl font-bold text-gray-700 text-center mb-4 md:mb-8">会社概要</h2>
      <FadeInSection>
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 md:gap-8">
          {/* 左側の地図セクション */}
          <div className="h-[300px] md:h-[450px]">
            <div className="w-full h-full" dangerouslySetInnerHTML={{ __html: company?.googlemap_iframe ?? "" }} />
          </div>

          {/* 右側の会社情報セクション */}
          <div className="md:bg-white p-2 md:p-6 rounded-lg md:shadow-md">
            
            <div className="space-y-6">
              <div>
                <h3 className="text-md md:text-lg font-semibold">[会社名]</h3>
                <p className='ps-2'>{company?.name ?? ""}</p>
              </div>
              <div>
                <h3 className="text-md md:text-lg font-semibold">[所在地]</h3>
                <div className='ps-2'>
                  <p>〒{company?.postcode ?? ""}</p>
                  <p>{company?.address ?? ""}</p>
                  <a href={company?.map_url ?? ""} className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">Googleマップで見る</a>
                </div>
              </div>
              <div>
                <h3 className="text-md md:text-lg font-semibold">[設立]</h3>
                <p className='ps-2'>{company?.establishment_date ?? ""}</p>
              </div>
	      <div>
                <h3 className="text-md md:text-lg font-semibold">[資本金]</h3>
                <p className='ps-2'>700万円（資本準備金含む）</p>
              </div>
              <div>
              <h3 className="text-md md:text-lg font-semibold">[事業内容]</h3>
                <div className='ps-2'
                dangerouslySetInnerHTML={{ __html: company?.business ?? "" }} 
                >

                </div>
              </div>
            </div>
          </div>
      </div>
      </FadeInSection>
    </section>
  );
};

export default CompanyInfoSection;
